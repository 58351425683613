<template>
  <tabs nav-class="nav-side" starting-tab="setup" :full-height="true">
    <tab
      :position="1"
      icon="email-at"
      id="setup"
      style="padding: 20px"
      class="form-horizontal"
    >
      <div class="form-group">
        <div class="col-md-12">
          <label for="subject">{{ $t('emailSubjectLine') }}</label>

          <div class="input-group" v-loading="aiLoading">
            <input
              id="subject"
              :value="action.subject"
              @blur="updateProperty"
              type="text"
              class="form-control"
              :placeholder="$t('emailSubjectInputPlaceholder')"
              ref="subject"
              :disabled="readOnly"
            />
            <div class="input-group-btn">
              <div
                class="open"
                v-if="showSubjectAttributes"
                v-click-outside="hideSubjectAttributes"
                style="position: absolute; right: 0; top: 32px"
              >
                <ul class="dropdown-menu dropdown-menu-right">
                  <li style="padding: 10px">
                    <profile-attributes
                      style="width: 300px"
                      :options="profileAttributes"
                      v-model="profileAttribute"
                      @input="subjectInsertAttribute"
                      :disabled="readOnly"
                    />
                  </li>
                </ul>
              </div>
              <div
                v-if="aiTextGeneration && !readOnly"
                class="btn btn-default"
                @click="getAiSubjectLine"
              >
                <icon glyph="ai" />
              </div>
              <div
                class="btn btn-default"
                @click.stop="showSubjectAttributes = true"
              >
                <span class="glyphicon glyphicon-user"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="col-md-6">
          <label for="from-address">{{ $t('emailFromAddress') }}</label>
          <multiselect
            id="from-address"
            :value="fromAddressValue"
            @input="updateFromAddress"
            :options="fromAddresses"
            :custom-label="optionsLabels"
            :select-label="$t('vueMultiSelectSelectLabel')"
            :selected-label="$t('vueMultiSelectSelectedLabel')"
            :deselect-label="$t('vueMultiSelectDeselectLabel')"
            :placeholder="displayFromPlaceholder"
            :disabled="readOnly || this.fromAddresses.length === 0"
          />
        </div>
        <div class="col-md-6">
          <label for="fromName">{{ $t('emailFromName') }}</label>
          <input
            id="fromName"
            :value="action.fromName"
            @blur="updateProperty"
            type="text"
            class="form-control"
            :placeholder="$t('emailFromNameInputPlaceholder')"
            :disabled="readOnly || this.fromAddresses.length === 0"
          />
        </div>
      </div>
      <div class="form-group">
        <div class="col-md-6">
          <label for="replyToAddress">{{ $t('emailReplyToAddress') }}</label>
          <input
            id="replyToAddress"
            :value="action.replyToAddress"
            @blur="updateProperty"
            type="text"
            class="form-control"
            :placeholder="$t('emailReplyToAddressInputPlaceholder')"
            :disabled="readOnly"
          />
        </div>
        <div class="col-md-6">
          <label for="replyToName">{{ $t('emailReplyName') }}</label>
          <input
            id="replyToName"
            :value="action.replyToName"
            @blur="updateProperty"
            type="text"
            class="form-control"
            :placeholder="$t('emailReplyNameInputPlaceholder')"
            :disabled="readOnly"
          />
        </div>
      </div>
      <div class="form-group">
        <div class="col-md-6">
          <label for="ignore_subscription">{{ $t('emailMessageType') }}</label>
          <select2
            :disabled="readOnly"
            v-model="config.ignore_subscription"
            @input="onIgnoreSubscriptionChange"
            class="form-control"
          >
            <option value="doNotIgnore">
              {{ $t('emailMessageTypeMarketing') }}
            </option>
            <option value="ignore">
              {{ $t('emailMessageTypeTransactional') }}
            </option>
          </select2>
        </div>
      </div>
      <national-exclusion-check-settings
        v-if="
          $store.getters.isFeatureEnabled('nationalExclusionCheckBetsson') ||
          $store.getters.isFeatureEnabled('nationalExclusionCheckOasis')
        "
        v-model="config.exclusion"
        :disabled="readOnly"
      />
      <div v-if="emailAttachmentsEnabled">
        <label>{{ $t('emailAttachPdf') }}</label>
        <div
          v-if="!readOnly"
          @dragover="onPdfDragOver"
          @dragleave="onPdfDragLeave"
          @drop="onPdfDragDrop"
          class="dropzone"
        >
          <p>{{ $t('emailDragToUploadPdf') }}</p>
          <label class="btn btn-file btn-default">
            <icon glyph="cloud-upload" />
            {{ $t('emailAttachPdfBrowse') }}
            <input type="file" accept=".pdf" @change="importFile" />
          </label>
        </div>
        <div
          v-for="(attachment, index) in this.config.attachments"
          :key="index"
        >
          <div v-if="attachment">
            <label>
              {{ $t('emailAttachedFile') }} {{ attachment.fileName }}
            </label>
            <button v-if="!readOnly" @click="clearFile(index)">
              {{ $t('emailAttachedFileDelete') }}
            </button>
          </div>
        </div>
        <div v-if="this.config.fileName != null">
          <label>
            {{ $t('emailAttachedFile') }} {{ this.config.fileName }}
          </label>
          <button v-if="!readOnly" @click="clearFileLegacy">
            {{ $t('emailAttachedFileDelete') }}
          </button>
        </div>
      </div>
      <div class="form-group" v-if="config.hasOwnProperty('promotion_id')">
        <div class="col-md-12">
          <label class="control-label">{{ $t('emailPromotionLabel') }}</label>
          <div>
            <el-autocomplete
              clearable
              :debounce="500"
              :placeholder="placeholder"
              :disabled="readOnly || !loaded"
              v-model="selectedPromotionName"
              :fetch-suggestions="querySearchPromotions"
              @select="updatePromotion"
              @clear="updatePromotion({})"
            />
          </div>
        </div>
      </div>

      <div
        class="form-group"
        v-if="
          config.hasOwnProperty('promotion_amount_required') &&
          config.promotion_amount_required
        "
      >
        <div class="col-md-12">
          <label class="control-label">
            {{ $t('emailPromotionAmountLabel') }}
          </label>
          <input
            class="form-control"
            type="number"
            v-model="config.promotion_amount"
            @change="updateConfig"
            :disabled="readOnly"
          />
        </div>
      </div>

      <div
        class="form-group"
        v-if="
          config.hasOwnProperty('promotion_duration_required') &&
          config.promotion_duration_required
        "
      >
        <div class="col-md-12">
          <label class="control-label">
            {{ $t('emailPromotionDurationLabel') }}
          </label>
          <input
            class="form-control"
            type="number"
            v-model="config.promotion_duration"
            @change="updateConfig"
            :disabled="readOnly"
          />
        </div>
      </div>
    </tab>
    <tab
      :position="2"
      v-if="!readOnly && action.contentId > 0"
      icon="pencil"
      id="editor"
      :keep-alive="true"
    >
      <component
        :is="editorComponent"
        :workflow="workflow"
        :action="action"
        @close="$emit('close')"
      />
    </tab>
    <tab :position="3" v-if="action.contentId > 0" icon="eye" id="preview">
      <iframe
        class="iframe-seamless"
        width="100%"
        height="100%"
        :srcdoc="content"
      ></iframe>
    </tab>
    <tab :position="5" v-if="!readOnly" icon="upload" id="upload">
      <upload :action="action" />
    </tab>
  </tabs>
</template>
<script>
import ProfileAttributes from '@/components/ProfileAttributes'

import Upload from './Email/Upload'
import Builder from './Email/Builder'
import Editor from './Email/Editor'

import Tabs from '@/components/TabSet'
import Tab from '@/components/Tab'

import vClickOutside from 'v-click-outside'
import SelectPromotion from '@/components/SelectPromotion'
import * as Toastr from 'toastr'
import XpApi from '@/libs/XpApi'
import IgnoreSubscription from '@/components/IgnoreSubscription'
import NationalExclusionCheckSettings from '@/components/NationalExclusionCheckSettings.vue'

export default {
  props: ['action', 'readOnly', 'workflow'],

  inject: ['workflowApi', 'contentApi'],

  components: {
    IgnoreSubscription,
    SelectPromotion,
    Upload,
    Builder,
    Editor,
    Tabs,
    Tab,
    ProfileAttributes,
    NationalExclusionCheckSettings
  },

  directives: {
    clickOutside: vClickOutside.directive
  },

  data() {
    const config = Object.assign(
      {
        exclusion: {
          check: false,
          category: null
        },
        pdf: null,
        fileName: null,
        attachments: [],
        ignore_subscription: 'doNotIgnore'
      },
      JSON.parse(JSON.stringify(this.action.config))
    )

    return {
      promotion: {},
      selectedPromotionName: '',
      editorComponent: false,
      content: '',
      loaded: false,
      aiLoading: false,
      showSubjectAttributes: false,
      profileAttribute: '',
      temporaryFileName: '',
      attachment: {},
      maxAttachments: 4,
      config
    }
  },

  created() {
    this.$store.dispatch('fetchSenderDetails')
    this.$store.dispatch('fetchAttributes')
    this.$store.dispatch('fetchPromotions').then((data) => {
      if (this.config.promotion_id) {
        const foundPromotion = data.find((promotion) => promotion.id === this.config.promotion_id)
        if (foundPromotion === undefined) {
          XpApi.get(`projects/${this.$route.params.projectId}/promotions`, {
            params: { id: this.config.promotion_id }
          }).then(({ data }) => {
            if (data.length === 0) {
              this.updatePromotion({})
            } else {
              this.promotion = data[0]
              this.selectedPromotionName = data[0].name
            }
          })
        } else {
          this.promotion = foundPromotion
          this.selectedPromotionName = foundPromotion.name
        }
      }
    })
    this.loaded = true
  },

  computed: {
    fromAddresses() {
      return this.$store.state.project.senderDetails
    },

    placeholder() {
      return this.loaded
        ? this.$t('selectOptionPlaceholder')
        : this.$t('loadingPlaceholder')
    },

    fromAddressValue() {
      if (this.action.fromName === '' && this.action.fromAddress === '') {
        return null
      }
      return { name: this.action.fromName, address: this.action.fromAddress }
    },

    displayFromPlaceholder() {
      if (!this.loaded) {
        return `${this.$t('emailFromAddressSelectLoadingPlaceholder')}`
      }

      if (this.fromAddresses.length === 0) {
        return this.$t('emailFromAddressSelectNoDetailsAvailable')
      }

      return this.$t('emailFromAddressSelectOption')
    },

    profileAttributes() {
      return this.$store.state.project.attributes
    },

    emailAttachmentsEnabled() {
      return this.$store.getters.isFeatureEnabled('emailAttachmentsEnabled')
    },

    aiTextGeneration() {
      return this.$store.getters.isFeatureEnabled('aiTextGeneration')
    }
  },

  watch: {
    'action.contentId': {
      immediate: true,
      handler(contentId) {
        if (contentId) {
          this.contentApi.load(contentId).then(({ content, meta }) => {
            this.content = content
            this.editorComponent = meta.editor || 'editor'
          })
        }
      }
    },
    'config.attachments': function () {
      this.updateConfig()
    },
    'config.exclusion': {
      handler() {
        this.updateConfig()
      },
      deep: true
    }
  },

  methods: {
    clearFile(index) {
      this.config.attachments.splice(index, 1)
      this.temporaryFileName = ''
    },

    clearFileLegacy() {
      this.temporaryFileName = ''
      this.config.pdf = null
      this.config.fileName = null
    },

    importFile(e) {
      this.processFileImport(e.target.files[0])
      e.target.value = ''
    },

    uploadPdf(file) {
      let projectId = this.$route.params.projectId
      const data = new FormData()
      data.append('attachment', file)
      XpApi.post('projects/' + projectId + '/campaigns/attachment', data)
        .then((response) => {
          this.updatePdfDetails(response.data.file)
        })
        .catch(({ response }) => {
          this.displayErrorDialog(response.data.message)
        })
    },

    getAiSubjectLine() {
      if (this.aiLoading) {
        return
      }

      let emailText = ''
      if (this.content) {
        emailText = new DOMParser()
          .parseFromString(this.content, 'text/html')
          .body.textContent.trim()
          .replace(/\r?\n|\r|\t/g, ' ')
          .replace(/\s+/g, ' ')
      }

      if (!emailText) {
        this.displayErrorDialog(this.$t('emailAiSubjectLineError'))
        return
      }

      this.aiLoading = true
      let projectId = this.$route.params.projectId
      XpApi.post('projects/' + projectId + '/generate/subject-line', {
        type: 'email',
        param: emailText
      })
        .then((response) => {
          this.action.subject = response.data.result
          this.workflowApi.updateAction(this.action.id, {
            ['subject']: this.action.subject
          })
          this.aiLoading = false
        })
        .catch((response) => {
          this.displayErrorDialog(response.data.message)
          this.aiLoading = false
        })
    },

    displayErrorDialog(msg) {
      Toastr.error(msg)
    },

    onPdfDragOver(e) {
      e.stopPropagation()
      e.preventDefault()
      e.currentTarget.classList.add('dropzone-active')
    },

    onPdfDragLeave(e) {
      e.stopPropagation()
      e.preventDefault()
      e.currentTarget.classList.remove('dropzone-active')
    },

    onPdfDragDrop(e) {
      e.stopPropagation()
      e.preventDefault()
      e.currentTarget.classList.remove('dropzone-active')
      this.processFileImport(e.dataTransfer.files[0])
    },

    processFileImport(file) {
      if (!file) {
        this.displayErrorDialog(this.$t('emailFileImportErrorFailedToHandle'))
        return
      }
      if (this.config.attachments.length >= this.maxAttachments) {
        this.displayErrorDialog(this.$t('emailFileImportErrorFailedToUpload'))
        return
      }
      if (file.type !== 'application/pdf') {
        this.displayErrorDialog(
          this.$t('emailFileImportErrorWrongFormat', { format: file.type })
        )
        return
      }
      this.uploadPdf(file)
      this.temporaryFileName = file.name
    },

    optionsLabels(option) {
      return option.address
    },

    updateProperty(e) {
      const prop = e.target.id,
        value = e.target.value

      if (this.action[prop] !== value) {
        this.workflowApi.updateAction(this.action.id, {
          [prop]: value
        })
      }
    },

    updateConfig() {
      this.workflowApi.updateAction(this.action.id, { config: this.config })
    },

    updatePdfDetails(path) {
      this.attachment = {
        fileName: this.temporaryFileName,
        pdf: path
      }

      this.config.attachments.push(this.attachment)
    },

    updatePromotion(selectedPromotion) {
      this.promotion = selectedPromotion
      this.selectedPromotionName = selectedPromotion ? selectedPromotion.name : ''
      this.config.promotion_id = selectedPromotion ? selectedPromotion.id : ''
      if (this.promotion) {
        this.config.promotion_amount_required =
          this.promotion.amount_required
        this.config.promotion_duration_required =
          this.promotion.duration_required
        if (!this.promotion.amount_required) {
          this.config.promotion_amount = null
        }
        if (!this.promotion.duration_required) {
          this.config.promotion_duration = null
        }
      } else {
        this.config.promotion_amount_required = false
        this.config.promotion_duration_required = false
      }

      this.updateConfig()
    },

    updateFromAddress(value) {
      if (this.action['fromAddress'] !== value.address) {
        let data = {
          ['fromAddress']: value.address,
          ['fromName']: value.name
        }

        if (
          this.action['replyToName'] === '' ||
          this.action['replyToName'] === this.action['fromName']
        ) {
          data['replyToName'] = value.name
        }

        if (
          this.action['replyToAddress'] === '' ||
          this.action['replyToAddress'] === this.action['fromAddress']
        ) {
          data['replyToAddress'] = value.address
        }

        this.workflowApi.updateAction(this.action.id, data)
      }
    },

    hideSubjectAttributes() {
      this.showSubjectAttributes = false
    },

    subjectInsertAttribute(profileAttribute) {
      this.showSubjectAttributes = false
      this.profileAttribute = ''

      const input = this.$refs['subject'],
        startPos = input.selectionStart,
        endPos = input.selectionEnd,
        value = `{{ ${profileAttribute} }}`

      this.workflowApi.updateAction(this.action.id, {
        subject:
          input.value.substring(0, startPos) +
          value +
          input.value.substring(endPos, input.value.length)
      })
      this.$nextTick(() => {
        input.selectionStart = endPos + value.length
        input.selectionEnd = endPos + value.length
        input.focus()
      })
    },

    onIgnoreSubscriptionChange(type) {
      this.config.ignore_subscription = type
      this.updateConfig()
    },

    querySearchPromotions(queryString, cb) {
      XpApi.get(`projects/${this.$route.params.projectId}/promotions`, {
        params: { name: queryString }
      }).then(({ data }) => {
        const list = data.map(item => {
          item.value = item.name
          return item
        })
        cb(list)
      })
    }
  }
}
</script>
<style lang="sass">
.el-autocomplete
  width: 100%
  padding-top: 2px

.dropzone
    text-align: center
    border: 2px dashed #ddd
    padding: 15px
    border-radius: 5px

.dropzone-active
    border: 2px dashed #888

.iframe-seamless
    background-color: transparent
    border: 0 none transparent
    padding: 0
    position: absolute
    top: 0
    left: 0
    bottom: 0
    right: 0
    z-index: 1
</style>

<style lang="scss" scoped>
::v-deep .category-selection {
  width: 50% !important;
  .select2-container--bootstrap {
    width: 100% !important;
  }
}
</style>
